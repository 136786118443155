import { inject, type InjectionKey } from 'vue';

export class InjectError extends Error {}

export function injectOrFail<T>(key: InjectionKey<T> | string): T {
  const output = inject<T>(key);

  if (!output) {
    throw new InjectError(`Failed to inject ${key.toString()}`);
  }

  return output;
}
